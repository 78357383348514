<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useLevels } from "~/stores/useLevels";

const props = defineProps({
  isMultiple: {
    type: Boolean,
    required: false,
    default: false,
  },
  showAll: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["onchange"]);

const mode = ref(props.isMultiple ? "tags" : "single");

const levelsStore = useLevels();

const { allUserLevels, allLevels } = storeToRefs(levelsStore);

onMounted(() => {
  levelsStore.initializeLevels();
});

const levels = computed(() => {
  const l = props.showAll ? allLevels.value : allUserLevels.value;
  l.sort((a, b) => a.displayOrder - b.displayOrder);

  return l;
});

const placeholder = computed(() => {
  return allUserLevels.value.length > 0
    ? "Select a level"
    : "Loading levels...";
});

const onChange = (value: any) => {
  if (typeof value == "object") {
    emit("onchange", value);
    return;
  }

  const level = allUserLevels.value.find((l) => l.id == value);

  if (level) {
    emit("onchange", level.name);
  }
};
</script>

<template>
  <BaseSelect
    :mode="mode"
    :searchable="true"
    :can-clear="true"
    :close-on-select="isMultiple != true"
    :hide-selected="isMultiple != true"
    :options="levels"
    value-prop="id"
    label="name"
    :placeholder="placeholder"
    @change="onChange"
    class=" bg-op"
  />
</template>

<style scoped></style>
